<template>
    <span >
        <Icon type="md-lock" color='red' v-if="status === 1" @click.native="unlock()" />
        <Icon type="md-unlock" v-else @click.native="lock()" />
    </span>
</template>

<script>

export default {
    name: "odd-lock",
    props: {
        value   : Number,    // 0不上锁 1上锁
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            status: this.value, // 锁的状态
        }
    },
    methods: {
        // 
        lock(){
            return
            this.status = 1;
            this._emit();
            this.api_update();
        },
        // end 
        unlock(){
            return
            this.status = 0;
            this._emit();
            this.api_update();
        },
        // end 
        _emit(){
            this.$emit('input', this.status);
        },
        // end 
        api_update(){
            let self = this;
        },
        // end 
    },
    mounted () {
    },
    watch: {
        value(status){
            this.status = status;
        }
    },
    computed: {
    }
}
</script>
<style scoped>
</style>