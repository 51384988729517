<template>
    <div >
        <!-- <Button @click="getData()" type="primary">历史记录</Button> -->
        <Modal
            scrollable
            :padding='0'
            v-model="modal1"
            title=""
            @on-ok="ok"
            @on-cancel="cancel"
            size ='small'
        >
            <!-- {{table_cols}} -->
            <Table :height='400' :row-class-name="rowClassName" :columns="table_cols" :data="table_data"></Table>
            <!-- <Card :padding='1' class="border space-y-2" :bordered="true" v-for="(nums, index) in data" :key="index">
                <div class="flex justify-between text-main bg-gray-100" v-if="nums.length>0 && index==0">
                    <div  v-if="nums[0].handicap && nums[0].handicap.length > 0 ">
                        <Row >
                            时间
                        </Row>
                    </div>
                    <div  v-if="nums[0].handicap && nums[0].handicap.length > 0 ">
                        <Row class="text-main space-x-1" v-for="(hand, index2) in nums[0].handicap" :key="index2">
                            盘口
                        </Row>
                    </div>
                    <div class="text-main space-x-1" v-for="(number, index3) in nums[0].numbers" :key="index3">
                        <div class="justify-center">
                            <Row>{{ number.name }}</Row>
                        </div>
                    </div>
                </div>
                <div class='flex justify-between' v-for="(item, index4) in nums" :key="index4">
                    <p class="text-main">
                        {{ $unit.tsp2str( item.tsp, 'HH:mm' ) }}
                    </p>
                    <div  v-if="item.handicap && item.handicap.length > 0">
                        <Row class="text-main space-x-1" v-for="(hand, index5) in item.handicap" :key="index5">
                            {{ hand.value }}
                        </Row>
                    </div>
                    <div class="text-main space-x-1" v-for="(number, index6) in item.numbers" :key="index6">
                        <div class="justify-center">
                            <Row>{{ number.value }}</Row>
                        </div>
                    </div>
                </div>
            </Card> -->
        </Modal>
    </div>
</template>
<script>

import { oddsHistory } from '@/api/ballsports/base/odds';
// import oddLock from './odd_lock.vue'

export default {
    name: "odd-history",
    props: {
        sport_id: Number,   // 运动id
        // match_id: Number,   // 比赛id
        platform_id: Number, // 平台id
        odd_id: Number, // 玩法id
        open_id: Number, // 平台比赛id
        // handicaps: {
        //     type: Array,
        //     default: function() {
        //         return ['2.75']
        //     }
        // }, 
        // value: {
        //     default: {},
        // }
        
    },
    components: {
        // oddLock,
    },
    data () {
        let self = (window.x = this)
        return {
            data: [],
            modal1: false,

            table_cols: [],
            table_data: [],
        }
    },
    methods: {
        initData(data, self_handicaps){
            // 创建表格标题和数据
            let self = this;
            let table_data = []
            let table_cols = []
            data.forEach((nums, index) => {
                // 筛选出盘口
                var row = {}
                var handicaps = []
                for ( var num_index  in nums){
                    var odd = nums[num_index]
                    handicaps = []
                    // 盘口
                    odd.handicap.forEach(hand => {
                        handicaps.push( hand.value );
                    })
                    // console.log(handicaps, self_handicaps, handicaps == self_handicaps)
                    if (handicaps.toString() == self_handicaps.toString()){
                        var generate_header = table_cols.length == 0   // 生成表格标题
                        if (generate_header){
                            table_cols.push({
                                    "key": "tsp",
                                    "title":'时间',
                                    "width": 125,
                                    "fixed": "left",
                                })
                        }
                        // 盘口正确
                        odd.handicap.forEach((hand, hand_index) => {
                            // 生成表格数据
                            var _key = 'handicap_'+hand_index
                            row[_key] = hand.value
                            if (generate_header){
                                table_cols.push({
                                        "key": _key,
                                        "title":'盘口',
                                        "minWidth": 90,
                                    })
                            }
                        })
                        // 指数
                        odd.numbers.forEach((item, item_index) => {
                            var _key = 'odd_'+item_index
                            row[_key] = item.value
                            if (generate_header){
                                table_cols.push({
                                        "key": _key,
                                        "title": item.name || '',
                                        "minWidth": 90,
                                    })
                            }
                        })
                        row['tsp'] = self.$unit.tsp2str( odd.tsp, 'MM-DD HH:mm')
                        row['status'] = odd.status
                        // if (generate_header){
                        //     row.status = 1
                        // }
                        table_data.push(row)
                        break
                    }
                }

            })
            self.table_data = table_data
            self.table_cols = table_cols
        },
        rowClassName (row, index) {
            if (row.status === 1) {
                return 'demo-table-disable-row';
            } 
            return '';
        },
        
        // 获取比赛的基础数据
        getData (handicaps) {
            let self = this;
            // 搜索日期
            var params = {
                sport_id: self.sport_id,
                open_id: self.open_id,
                platform_id: self.platform_id,
                odd_id: self.odd_id,
                // debug: 1,
            }
            oddsHistory( params ).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    if (response.data.data ){
                        self.data = response.data.data.list;
                        // console.log(response.data.data)
                        // self.createTableHeader()
                        // self.createTableData()
                        self.initData(response.data.data.list, handicaps);
                        self.modal1 = true
                    }else{
                        // this.data_dic = {}
                    }
                } else {
                    self.$Message.error(response.data.msg)
                }
            })
        },
        // end
        ok(){},
        cancel(){},
    },
    mounted () {
    },
    watch: {
        // 'value': {
        //     handler(value){
        //         // console.log("Odd Get", value);
        //         let self = this;
        //         // self.getData();
        //         self.data = value;
        //     },
        //     deep: true,
	    //     immediate: true,  // 深度触发
        // },
    },
    computed: {
    }
}
</script>


