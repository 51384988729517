<template>
    <div class="overflow-x-auto w-60 my-1">
        <!-- {{ data }} -->
        <oddHistory ref='history' :sport_id="sport_id" :platform_id="platform_id" :odd_id="odd_id" :open_id="open_id"></oddHistory>
        <!-- <Table border :row-class-name="rowClassName" :columns="table_cols" :data="table_data"></Table> -->

        <!-- <Row class="flex justify-evenly ">
            <Col class="flex justify-center bg-gray-100" v-for="(handicap, index) in table_cols" :key="index">
                {{handicap.title}}
            </Col>
        </Row>
        <Row class="flex justify-evenly " v-on:click.native="onHandicapClicked(null, index)" v-for="(item, index) in table_data" :key="index">
            <Col class="flex justify-center" v-for="(col, index2) in table_cols" :key="index2">
                {{item[col.key]}}
            </Col>
        </Row> -->
        <!-- v-animate-css.click="animationObject" -->
        <table :class=" 'table-fixed my-0 ' + (flash? 'animated flash': '') " 
        >
            <thead>
                <tr class="bg-blue-50 border">
                    <th :class=" 'text-main border ' + (index===0? ' w-24' : '')"  v-for="(handicap, index) in table_cols" :key="index">
                        <Poptip v-if="handicap.desc" trigger="hover" title="" :content="handicap.desc">
                            <span>{{handicap.title}}</span>
                        </Poptip>
                        <span v-else>{{handicap.title}}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr :class=" 'border hover:bg-blue-50 ' + (item.status == 1? ' bg-gray-300 ': '')" 
                    v-if="showUp(index)"  
                    @click="onHandicapClicked(null, index)" 
                    v-for="(item, index) in table_data" 
                    :key="index"
                >
                    <td align="center" class=" text-main border  " v-for="(col, index2) in table_cols" :key="index2">
                        {{ item[col.key] }} 
                    </td>
                </tr>
            </tbody>
        </table>
        <div class=" flex justify-center">
            <Button long v-if="table_data.length>3 && show_more==false" type="text" icon="ios-arrow-down" @click="show_more=true"></Button>
            <Button long v-if="table_data.length>3 && show_more==true" type="text" icon="ios-arrow-up" @click="show_more=false"></Button>
        </div>

        <!-- <Button @click="doFlash">闪烁</Button> -->

        <!-- <Card v-on:click.native="onHandicapClicked(item)" shadow :padding='5' class="border space-y-1" :bordered="true" v-for="(item, index) in initOddData()" :key="index">
            <div class='bg-gray-200 flex justify-between'>
                <p class="text-main">
                    {{ $unit.tsp2str( item.tsp, 'HH:mm' ) }}
                </p>
                <div  v-if="item.handicap && item.handicap.length > 0">
                    <span class="text-main">盘口: </span>
                    <span class="text-main space-x-1" v-for="(hand, index3) in item.handicap" :key="index3">
                        {{ hand.value }}
                    </span>
                </div>
                <span>
                    <oddLock v-model="item.status"></oddLock>
                </span>
            </div>
            <div class="flex justify-between">
                <div class="text-main space-x-1" v-for="(number, index2) in item.numbers" :key="index2">
                    <div class="justify-center">
                        <Row>{{ number.name }}</Row>
                        <Row>{{ number.value }}</Row>
                    </div>
                </div>
            </div>
        </Card> -->
    </div>
</template>
<script>

// import { oddsGet } from '@/api/ballsports/base/odds';
import oddLock from './odd_lock.vue'
import oddHistory from './odd_history.vue'

export default {
    name: "odd-show",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
        platform_id: Number, // 平台id
        platform_name: String, // 平台名称
        odd_id: Number, // 玩法id
        open_id: Number, // 平台比赛id
        value: {
            default: {},
        },
    },
    components: {
        oddLock,
        oddHistory,
    },
    data () {
        let self = (window.x = this)
        return {
            data: {
                "odd_data": {
                    "now": [],
                }
            },
            table_data: [],
            table_cols: [],
            show_more: false,  // 表格是否展示更多
            // 
            animationObject:{
                classes: 'flash',
                delay: 0,
                duration: 1000,
                iteration: 2, //重复几次
            },
            flash: false,
        }
    },
    methods: {
        showUp( index ){
            let self = this;
            if (index<=2){
                return true
            }else{
                return self.show_more
            }
        },
        // end 

        initOddData(){
            let self = this;
            if (self.data && self.data.odd_data && self.data.odd_data.now){
                return self.data.odd_data.now
            } else{
                return []
            }
        },

        initTableData(){
            // 创建表格标题和数据
            let self = this;
            let table_data = []
            let table_cols = []
            if (self.data.odd_data){
                self.data.odd_data.now.forEach((odd, index) => {
                    // 筛选出盘口
                    var row = {}
                    if (index == 0){
                        table_cols.push({
                            "key": "tsp",
                            "title": self.platform_name || '时间',
                            "width": 140,
                            "fixed": "left",
                        })
                    }
                    // 盘口正确
                    odd.handicap.forEach((hand, hand_index) => {
                        // 生成表格数据
                        var _key = 'handicap_'+hand_index
                        row[_key] = hand.value
                        if (index == 0){
                            table_cols.push({
                                "key": _key,
                                "title":'盘口',
                                "minWidth": 80,
                                "handicap": 1,
                                "desc": hand.desc || '',
                            })
                        }
                    })
                    // 指数
                    odd.numbers.forEach((item, item_index) => {
                        var _key = 'odd_'+item_index
                        row[_key] = item.value
                        if (index == 0){
                            table_cols.push({
                                "key": _key,
                                "title": item.name || '',
                                "minWidth": 80,
                            })
                        }
                    })
                    row['tsp'] = self.$unit.tsp2str( odd.tsp, 'MM-DD HH:mm' )
                    row['status'] = odd.status
                    table_data.push(row)
                })
            }
            self.table_data = table_data
            self.table_cols = table_cols
        },

        rowClassName (row, index) {
            if (row.status === 1) {
                return 'demo-table-disable-row';
            } 
            return '';
        },

        onHandicapClicked(item, index){
            // 盘口点击
            let self = this;
            var handicaps = []
            // console.log(item)
            if (item){
            }else {
                // 根据索引
                try {
                    item = self.data.odd_data.now[index]
                } catch (error) {
                    console.log(error)
                    return 
                }
            }
            if (item.handicap){
                item.handicap.forEach(element => {
                    handicaps.push(element.value)
                });
            }
            // console.log(handicaps)
            self.$refs.history.getData(handicaps);
        },
        // end
        doFlash(){
            let self = this;
            self.flash = true;
            setTimeout(function(){ 
                self.flash=false
            }, 2000); 
        },
        // end
    },
    mounted () {
    },
    watch: {
        'value': {
            handler(value){
                // console.log("Odd Get", value);
                let self = this;
                self.data = value;
                self.initTableData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        
    },
    computed: {
    }
}
</script>
<style scoped >
    @import "odd.css"
</style>


