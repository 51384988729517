import { render, staticRenderFns } from "./odd_lock.vue?vue&type=template&id=dd4d0b96&scoped=true&"
import script from "./odd_lock.vue?vue&type=script&lang=js&"
export * from "./odd_lock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd4d0b96",
  null
  
)

export default component.exports