<template>
<div>
    <Row class="title-sub">比赛信息:</Row>
    <marchInfo :sport_id="sport_id" :match_id="match_id"></marchInfo>
    <Row class="gap-x-3" > 
        <Input v-model="search_key"  placeholder="搜索玩法" clearable style="width: 200px" />
        <Checkbox-group v-if="tableColumnsChecked && platforms" v-model="tableColumnsChecked" @on-change="checkTableColumns" >
            <Checkbox v-for="(item, index) in platforms" :key="index" :label="String(item.platform_id)" >
                {{item.name}}
            </Checkbox>
        </Checkbox-group>
    </Row>
    <!-- <fitTable 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable> -->

    <!-- {{ data }} -->
    <!-- <Row :gutter="8" v-for="(item, index) in data" :key="item.odd_id"  >
        <Col  class="flex justify-start">
            <span class="text-link">{{ item.odd_id }}</span>
            <span class="text-main">{{ item.odd_name }}</span>
        </Col>
        <Col v-for="(platform, index2) in platforms" :key="platform.platform_id" v-show="tableColumnsChecked.indexOf( String(platform.platform_id)) >-1">
            <oddShow 
                :sport_id="sport_id" 
                :platform_id="platform.platform_id" 
                :platform_name="platform.name"
                :open_id="platform.open_id" 
                :odd_id="item.odd_id" 
                :value="item['p_' + platform.platform_id]"
            ></oddShow>
        </Col>
    </Row> -->
    <table class="table-fixed " v-if="data_dic.odds.length > 0">
        <thead>
            <tr class="bg-gray-100 border">
                <th class="border text-main w-40"  >
                    <Poptip word-wrap width="200" content="content" 
                    >
                        <span>玩法</span>
                        <template #content>
                            <CheckboxGroup v-model="odd_categorys">
                                <Checkbox v-for="(category, index) in data_dic.odd_categorys" :key="index" :label="category">
                                    <span class="text-link">{{category}}</span>
                                </Checkbox>
                            </CheckboxGroup>
                            <!-- <Button small type="text" icon="ios-refresh" long @click=""></Button> -->
                        </template>
                    </Poptip>
                </th>
                <th class="border text-main"  v-for="(platform, index2) in platforms" :key="platform.platform_id" v-show="tableColumnsChecked.indexOf( String(platform.platform_id)) >-1">
                    <span class="text-link">{{ platform.platform_id }}</span>
                    {{platform.name}} - {{ platform.open_id }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="border"  v-for="(item, index) in data" :key="item.odd_id" 
                    v-show="
                        matchSearchKey(item.odd_id, item.odd_name)
                        &&
                        (odd_categorys.length===0 || (odd_categorys.length>0 && odd_categorys.indexOf(item.odd_category) > -1)) 
                    ">
                <td class=" text-main flex justify-start ">
                    <!-- {{item}} -->
                    <span class="text-link">{{ item.odd_id }}</span>
                    <span class="text-main">{{ item.odd_name }}</span>
                </td>
                <td align="center" class="border text-main " v-for="(platform, index2) in platforms" :key="platform.platform_id" v-show="tableColumnsChecked.indexOf( String(platform.platform_id)) >-1">
                    <oddShow 
                        :ref="'odds_'+platform.platform_id+'_'+item.odd_id"
                        :sport_id="sport_id" 
                        :platform_id="platform.platform_id" 
                        :platform_name="platform.name"
                        :open_id="platform.open_id" 
                        :odd_id="item.odd_id" 
                        :value="item[ 'p_' + platform.platform_id ]"
                    ></oddShow>
                </td>
            </tr>
        </tbody>
    </table>
    <div v-else-if="spinShow==false" class="title-sub">数据获取中...</div>
    <div v-else class="title-sub">暂无指数数据</div>
    <!-- <Row>
        <Col v-for="(item, index) in data" :key="item.odd_id" align="middle" >
            <div class="border p-2">
                <Row justify="start">
                    <span class="text-link">{{ item.odd_id }}</span>
                    <span class="text-main">{{ item.odd_name }}</span>
                </Row>
                <Row v-for="(platform, index2) in platforms" :key="platform.platform_id" v-show="tableColumnsChecked.indexOf( String(platform.platform_id)) >-1">
                    <oddShow 
                        :sport_id="sport_id" 
                        :platform_id="platform.platform_id" 
                        :platform_name="platform.name"
                        :open_id="platform.open_id" 
                        :odd_id="item.odd_id" 
                        :value="item['p_' + platform.platform_id]"
                    ></oddShow>
                </Row>
            </div>
        </Col>
    </Row> -->

    <!-- <div class=" ">
        <div v-for="(item, index) in data" :key="item.odd_id" align="middle" >
            <Row justify="start">
                <span class="text-link">{{ item.odd_id }}</span>
                <span class="text-main">{{ item.odd_name }}</span>
            </Row>
            <Row v-for="(platform, index2) in platforms" :key="platform.platform_id" v-show="tableColumnsChecked.indexOf( String(platform.platform_id)) >-1">
                <oddShow 
                    :sport_id="sport_id" 
                    :platform_id="platform.platform_id" 
                    :platform_name="platform.name"
                    :open_id="platform.open_id" 
                    :odd_id="item.odd_id" 
                    :value="item['p_' + platform.platform_id]"
                ></oddShow>
            </Row>
        </div>
    </div> -->

</div>
</template>
<script>

import { oddsGet } from '@/api/ballsports/base/odds';
import fitTable from '../../basic/table.vue';
import oddShow from './odd.vue';
import Client from '@/utils/mqtt/mqtt-pro-new';
import marchInfo from '../match/info.vue'

export default {
    name: "odds-detail",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
        fitTable,
        oddShow,
        marchInfo,
    },
    data () {
        let self = (window.x = this)
        return {
            loading: false,
            data_dic: {
                "odds": [],
                "platforms": [],
                "odd_categorys": []
            },
            columns: [],
            data: [],
            platforms: [],
            tableColumnsChecked: null, 
            // 筛选类别
            odd_categorys: [],
            search_key: '',
            spinShow: false,
        }
    },
    methods: {
        matchSearchKey(odd_id, odd_name){
            let self = this;
            if (self.search_key){
                if( odd_name.indexOf(self.search_key) > -1 ){
                    return true
                }
                if (String(odd_id).indexOf(self.search_key) > -1){
                    return true
                }
                return false
            }else{
                return true
            }
        },
        // 
        createTableHeader(){
            let self = this;
            let data_dic_odds = self.data_dic.odds || [];
            var filter_odds = []
            data_dic_odds.forEach(element => {
                filter_odds.push({
                    "label": element.odd_name,
                    "value": element.odd_id,
                })
            })
            var columns = [{
                key: 'odd_name',
                width: 200,
                align: 'left',
                tree: true,
                fixed: 'left',
                // 过滤
                filters: filter_odds,
                filterMultiple: true,
                filterMethod (value, row) {
                    // console.log(value)
                    return row.odd_id == value
                },
                renderHeader: (h, params) => {
                    return [
                        h('span',  '指数'),
                    ]
                },
                render: function (h, params) {
                    let currentRow = params.row;
                    return [
                        h('span', {class: 'text-link '}, "["+currentRow.odd_id +"]"),
                        h('span', currentRow ? currentRow.odd_name: ''),
                    ]
                }
            }]
            if (self.data_dic.platforms && (typeof self.data_dic.platforms !== 'undefined')){
                // 1 首先默认选择所有平台
                self.platforms = self.data_dic.platforms
                if ( ! self.tableColumnsChecked ){
                    self. tableColumnsChecked = []
                    self.data_dic.platforms.forEach(element => {
                        self.tableColumnsChecked.push( String(element.platform_id) )
                    })
                }
                self.data_dic.platforms.forEach(element => {
                    let platform_id = element.platform_id
                    if (self.tableColumnsChecked.indexOf(String(platform_id)) > -1 ){
                        let open_id = element.open_id
                        let _key = 'p_' + platform_id
                        columns.push({
                            key: _key,
                            align: 'left',
                            tree: true,
                            fixed: 'left',
                            renderHeader: (h, params) => {
                                return [
                                    h('span', element ? element.name: ''),
                                    h('span', {class: 'text-link '}, "["+ platform_id +"]"),
                                    h('span', " - "),
                                    h('span', open_id),
                                ]
                            },
                            render: function (h, params) {
                                let currentRow = params.row;
                                let col = currentRow[_key]
                                // console.log("col: ", col, )
                                if (col){
                                    return [
                                        // h('span', {class: 'flex-nowrap mr-1'}, _key),
                                        // h('span', {class: 'flex-nowrap '}, JSON.stringify(col) ),
                                        h(oddShow, {
                                            props: {
                                                'value': col,
                                                "sport_id": self.sport_id,
                                                "platform_id": platform_id,
                                                "open_id": open_id,
                                                "odd_id": currentRow.odd_id
                                            },
                                            // on: {
                                            //     'onChange': (val) => {
                                            //         // self.updateRow( { id: currentRow.id, 'personal_fouls': val } );
                                            //     }
                                            // }
                                        })
                                    ]
                                }
                            },
                        })
                    }
                });
            }
            self.columns = columns;
        },

        createTableData(team){
            let self = this;
            self.data = self.data_dic.odds || [];
            self.odd_categorys = self.data_dic.odd_categorys || [];
        },
        // 获取比赛的基础数据
        getData () {
            let self = this;
            // 搜索日期
            if (self.match_id > 0){
                var params = {
                    sport_id: self.sport_id,
                    match_id: self.match_id,
                    // debug: 1,
                }
                oddsGet( params ).then(response => {
                    // console.log(response)
                    self.spinShow = true;
                    if (response.data.code == 0) {
                        if (response.data.data ){
                            self.data_dic = response.data.data;
                            // console.log(response.data.data)
                            self.createTableHeader()
                            self.createTableData()
                        }else{
                            // this.data_dic = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                // self._init_data();
            }
        },
        checkTableColumns(){
            let self = this;
            self.createTableHeader()
        },
        socketConnetc (topic) {
            let self = this;
            // if ( self.mqtt_client ){
            //     self.mqtt_client.unsubscribe(topic);
            //     self.mqtt_client.subscribe(topic);
            // }else{
                self.mqtt_client = new Client({
                    topic: `${topic}`,
                    message (t, msg) {
                        // console.log("msg")
                        if (msg.type === 1 ){
                            var msgData = msg.data;
                            // console.log(msgData);
                            // 更新指数数据
                            var odd_id = msgData.odd_id;
                            var platform_id = msgData.platform_id
                            // 遍历数据
                            var data_dic_odds = self.data_dic.odds || [];
                            for (var i in data_dic_odds){
                                var row = self.data_dic.odds[i];
                                if (row.odd_id == odd_id){
                                    var platform_key = "p_"+platform_id
                                    if (row[platform_key]){
                                        row[platform_key].odd_data.now = msgData.odd_data.now;
                                        var ref_name = 'odds_'+platform_id+'_'+odd_id;
                                        if (self.$refs[ref_name] && self.$refs[ref_name].length>0){
                                            // console.log( self.$refs[ref_name] )
                                            self.$refs[ref_name][0].doFlash()
                                        }
                                    }else{
                                        // 源不存在 重新请求接口
                                        self.getData()
                                    }
                                    break;
                                }
                            }
                        }
                    },
                    connect(){
                    },
                })
            // }
        },
        // end 
    },
    mounted () {
        // 长连接
        var topic = "namicms/odds/"+ this.sport_id + "_" + this.match_id;
        this.socketConnetc(topic); //
    },
    watch: {
        'match_id': {
            handler(value){
                console.log("Odds Detail Get", value);
                let self = this;
                self.getData();
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>