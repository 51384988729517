<template>
    <Card :bordered="false" :padding="10">
        <!-- {{data}} -->
        <Row class='text-main'>比赛: <span class="text-link">{{ data.id }}</span></Row>
        <Row class='text-main'>时间: {{ $unit.tsp2str( data.match_time ) }}</Row>
        <Row class='text-main'>赛事: <span class="text-link"> [{{data.unique_tournament.id}}] </span>{{data.unique_tournament.name}}</Row>
        <Row class='text-main'>战队: {{data.home.name}}<span class="text-link">[{{data.home.id}}] </span> 
             vs {{data.away.name}}<span class="text-link">[{{data.away.id}}] </span>
        </Row>
    </Card>
</template>
<script>

import { getMatch } from '@/api/ballsports/base/match';
// import {getImgUrl} from '@/utils/util';

export default {
    name: "match-info",
    props: {
        sport_id: Number,   // 运动id
        match_id: Number,   // 比赛id
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            loading: true,
            data: {
                    sport_id: this.sport_id,
                    home: {},
                    away: {},
                    weather: {},
                    unique_tournament: {},
                    season: {},
                    tournament: {},
                    venue: {},
                    match_time: parseInt( Date.parse(new Date())/1000 ),
                },
        }
    },
    methods: {
        _init_data(){
            this.data = {
                    sport_id: this.sport_id,
                    home: {},
                    away: {},
                    weather: {},
                    unique_tournament: {},
                    season: {},
                    tournament: {},
                    venue: {},
                    match_time: parseInt( Date.parse(new Date())/1000 ),
                }
        },
        // end
        getData () {
            let self = this;
            // 搜索日期
            if (self.match_id > 0){
                var params = {
                    sport_id: self.sport_id,
                    match_id: self.match_id,
                }
                getMatch( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self._init_data();
            }
        },
        // end
    },
    mounted () {
        // console.log(this.sport_id)
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>