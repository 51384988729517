import http from '@/utils/http';

// 获取队伍列表
export const oddsGet = (params) => {
    return http.request({
        url: '/v1/ballsports/base/odds/get',
        method: 'get',
        params: params
    })
}

export const oddsHistory = (params) => {
    return http.request({
        url: '/v1/ballsports/base/odds/history',
        method: 'get',
        params: params
    })
}